@import './font/index.css';
@import './styles/css/Dark.css';
@import './styles/css/Light.css';

body {
  font-size: 14px !important;
  background-color: var(--color-base-foreground-ground-alt) !important;
  height: 100%;
  background-image: url('./images/background.svg');
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body,
html {
  height: 100%;
}
:root {
  --mode-white: var(--color-base-foreground-elevated-0) !important;
  --mode-primary: var(--comp-badge-color-background-primary) !important;
  --bs-link-color: var(--comp-badge-color-background-primary) !important;
  --bs-primary-rgb: 77, 91, 212 !important;
  --bs-border-color: var(--color-on-base-border-disable) !important;
  --bg-nav: #ffffffcf;
}
:root[data-dark-mode='0'] {
  --bg-nav: #121a28cf;
}
[data-dark-mode='0'] body {
  background-image: url('./images/background-dark.svg');
}

.btn {
  --bs-btn-font-size: 14px !important;
}
.text-primary-1 {
  color: var(--comp-badge-color-background-primary) !important;
}

.btn-primary {
  --bs-btn-color: var(--comp-btn-color-background-secondary-normal) !important;
  --bs-btn-bg: var(--comp-btn-color-background-primary-normal) !important;
  --bs-btn-border-color: var(
    --comp-btn-color-background-primary-normal
  ) !important;
  --bs-btn-hover-bg: var(--comp-btn-color-background-primary-hover) !important;
  --bs-btn-hover-border-color: var(
    --comp-btn-color-background-primary-hover
  ) !important;
  --bs-btn-active-bg: var(--comp-btn-color-background-primary-hover) !important;
  --bs-btn-active-border-color: var(
    --comp-btn-color-background-primary-hover
  ) !important;
  --bs-btn-disabled-bg: var(
    --comp-btn-color-background-primary-normal
  ) !important;
  --bs-btn-disabled-border-color: var(
    --comp-btn-color-background-primary-normal
  ) !important;
}
.bg-them-primary {
  background-color: var(--comp-badge-color-background-primary) !important;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: var(--color-base-foreground-ground-alt) !important;
  --bs-btn-border-color: var(--color-base-foreground-ground-alt) !important;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.bg-white-mode {
  background-color: var(--color-base-foreground-elevated-0) !important;
}

.content-center {
  display: flex;
  min-height: 100vh;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 1.25rem;
}

.font-family {
  color: var(--comp-alert-color-content-default);
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

.text-default {
  /* color: var(--comp-alert-color-content-default); */
  color: var(--color-on-base-content-subtle) !important;
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}
.text-title {
  color: var(--color-on-base-content-high);
  font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

.card {
  background-color: var(--color-base-foreground-elevated-0) !important;
  box-shadow: 0px 0px 1px 0px var(--shadow-below-sm-1-color),
    0px 4px 16px -4px var(--shadow-below-sm-0-color);
  --bs-card-border-color: var(--color-on-base-border-disable) !important;
}

tbody {
  color: var(--color-on-base-content-subtle) !important;
}

.table {
  --bs-table-border-color: var(--color-on-base-border-disable) !important;
}

.h-full {
  height: 80vh !important;
}

.font-small-2 {
  font-size: 10px !important;
}
.input-group.is-invalid .input-group-text {
  border-color: #ea5455 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.box-text-1 {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer-event {
  cursor: pointer !important;
}

.active-menu {
  background-color: var(--comp-badge-color-background-primary);
}

.default-menu {
  background-color: var(--color-base-foreground-ground-alt);
}

.bg-white,
.form-control,
.form-control:focus,
.input-group-text {
  background-color: var(--color-base-foreground-elevated-0) !important;
}

input.form-control {
  height: 34px !important;
  border-color: var(--color-on-base-border-disable) !important;
}
.form-control {
  color: var(--color-on-base-content-subtle) !important;
  font-size: 14px !important;
}

textarea.form-control {
  border-color: var(--color-on-base-border-disable) !important;
}
.input-group-text {
  border-color: var(--color-on-base-border-disable) !important;
}
.form-control:focus {
  /* border-color: var(--comp-btn-color-background-primary-pressed) !important; */
  border-color: var(--color-on-base-border-disable) !important;
  box-shadow: none !important;
}

.form-control:disabled {
  background-color: var(--color-on-base-border-disable) !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-base-foreground-ground-alt);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.spinner-loading {
  animation: 0.75s linear infinite spinner-border !important;
}

::placeholder {
  color: var(--color-on-base-content-low);
}
::-ms-input-placeholder {
  color: var(--color-on-base-content-low);
}
