/**
 * Do not edit directly
 * Generated on Mon, 25 Dec 2023 10:54:08 GMT
 */

:root {
  --comp-bg: './images/background.svg';
  --comp-badge-color-background-primary: #4d76ff;
  --comp-badge-color-content-primary: #ffffff;
  --comp-badge-color-background-primary-subtle: #fbfbfe;
  --comp-badge-color-content-primary-subtle: #4d76ff;
  --comp-badge-color-background-default: #f0f2f6;
  --comp-badge-color-background-disable: #f9fafb;
  --comp-badge-color-background-success: #3d8c4e;
  --comp-badge-color-background-success-subtle: #e2fbe8;
  --comp-badge-color-background-error: #c5312f;
  --comp-badge-color-background-error-subtle: #fff2f2;
  --comp-badge-color-background-warning: #ddac29;
  --comp-badge-color-background-warning-subtle: #fef5e2;
  --comp-btn-color-background-primary-normal: #4d76ff;
  --comp-btn-color-background-primary-hover: #304ffe;
  --comp-btn-color-background-primary-pressed: #13acc7;
  --comp-btn-color-background-secondary-normal: #fbfbfe;
  --comp-btn-color-background-secondary-hover: #f2f3fc;
  --comp-btn-color-background-secondary-pressed: #ffecdb;
  --comp-btn-color-background-default-normal: #ffffff;
  --comp-btn-color-background-default-hover: #f0f2f6;
  --comp-btn-color-background-default-pressed: #e4e7ee;
  --comp-btn-color-background-disable: #f9fafb;
  --comp-btn-color-content-primary-normal: #ffffff;
  --comp-btn-color-content-primary-hover: #ffffff;
  --comp-btn-color-content-primary-pressed: #ffffff;
  --comp-btn-color-content-secondary-normal: #4d76ff;
  --comp-btn-color-content-secondary-hover: #304ffe;
  --comp-btn-color-content-secondary-pressed: #13acc7;
  --comp-btn-color-border-primary-normal: #c9d0f4;
  --comp-btn-height-large: 48;
  --comp-btn-height-medium: 40;
  --comp-btn-height-small: 32;
  --comp-btn-padding-hori-large: 16;
  --comp-btn-padding-medium: 16;
  --comp-btn-padding-small: 12;
  --comp-btn-radii-default: 8;
  --comp-btn-radii-large: 12;
  --comp-btn-border-wiidth-secondary: 1;
  --comp-alert-color-content-default: #263146;
  --comp-alert-color-background-default: #f0f2f6;
  --comp-alert-color-content-error: #ef5f5e;
  --comp-alert-color-background-error: #fff2f2;
  --comp-alert-color-content-warning: #ddac29;
  --comp-alert-color-background-warning: #fef5e2;
  --comp-alert-color-content-success: #3d8c4e;
  --comp-alert-color-background-success: #e2fbe8;
  --comp-alert-radii-default: 8;
  --comp-alert-padding-default: 16;
  --color-chart-foreground-unit-1: #4d76ff;
  --color-chart-foreground-unit-2: #3b82f6;
  --color-chart-foreground-unit-3: #b130b3;
  --color-chart-foreground-unit-4: #facc15;
  --color-chart-foreground-unit-5: #95e63c;
  --color-chart-foreground-unit-6: #f94144;
  --color-chart-foreground-unit-7: #7b818e;
  --color-chart-foreground-unit-8: #e4e4e4;
  --color-primary-foreground-default: #4d76ff;
  --color-primary-foreground-hover: #304ffe;
  --color-primary-foreground-pressed: #13acc7;
  --color-primary-foreground-subtle: #fbfbfe;
  --color-primary-foreground-subtle-hover: #f2f3fc;
  --color-primary-foreground-subtle-pressed: #ffecdb;
  --color-primary-border-default: #c9d0f4;
  --color-primary-content-default: #4d76ff;
  --color-primary-content-hover: #304ffe;
  --color-primary-content-pressed: #13acc7;
  --color-on-primary-border-default: rgba(255, 255, 255, 0.4);
  --color-on-primary-content-high: #ffffff;
  --color-on-primary-content-medium: rgba(255, 255, 255, 0.82);
  --color-on-primary-content-low: rgba(255, 255, 255, 0.53);
  --color-functional-foreground-error: #c5312f;
  --color-functional-foreground-error-hover: #a3201a;
  --color-functional-foreground-error-pressed: #881a10;
  --color-functional-foreground-error-subtle: #fff2f2;
  --color-functional-foreground-error-subtle-hover: #ffe8e8;
  --color-functional-foreground-error-subtle-pressed: #ffd3d4;
  --color-functional-foreground-warning: #ddac29;
  --color-functional-foreground-warning-subtle: #fef5e2;
  --color-functional-foreground-success: #3d8c4e;
  --color-functional-foreground-success-subtle: #e2fbe8;
  --color-functional-foreground-info: #3268c4;
  --color-functional-foreground-info-subtle: #eff5ff;
  --color-functional-border-error: #ffb9ba;
  --color-functional-content-error: #ef5f5e;
  --color-functional-content-error-hover: #a3201a;
  --color-functional-content-error-pressed: #881a10;
  --color-functional-content-warning: #ddac29;
  --color-functional-content-success: #3d8c4e;
  --color-functional-content-info: #3268c4;
  --color-base-foreground-ground: #ffffff;
  --color-base-foreground-ground-alt: #F9FAFB;
  --color-base-foreground-elevated-0: #ffffff;
  --color-base-foreground-elevated-4: #ffffff;
  --color-base-foreground-elevated-8: #ffffff;
  --color-base-foreground-elevated-16: #ffffff;
  --color-base-foreground-subtle: #f0f2f6;
  --color-base-foreground-moderate: #e4e7ee;
  --color-base-foreground-invert: #0b1019;
  --color-base-foreground-disable: #f9fafb;
  --color-base-foreground-hover: #f0f2f6;
  --color-base-foreground-pressed: #e4e7ee;
  --color-base-foreground-error-hover: #fff2f2;
  --color-base-foreground-error-pressed: #ffe8e8;
  --color-base-foreground-primary-hover: #fbfbfe;
  --color-base-foreground-primary-pressed: #f2f3fc;
  --color-base-foreground-transparent-focused: rgba(255, 255, 255, 0.01);
  --color-on-base-border-default: #c0c7d6;
  --color-on-base-border-disable: #e4e7ee;
  --color-on-base-content-high: #263146;
  --color-on-base-content-medium: #4d5b76;
  --color-on-base-content-low: #a7b1c4;
  --color-on-base-content-disable: #d4d9e4;
  --color-on-base-content-invert: #ffffff;
  --color-on-base-content-subtle: #5f5f5f;
  --color-on-base-foreground-default: #ececec;
  --color-accent-content-red: #c5312f;
  --color-accent-content-orange: #bd5904;
  --color-accent-content-yellow: #ab7506;
  --color-accent-content-lime: #6b8b1e;
  --color-accent-content-green: #3d8c4e;
  --color-accent-content-teal: #1e8878;
  --color-accent-content-sky: #0f7a94;
  --color-accent-content-blue: #3268c4;
  --color-accent-content-indigo: #4a58d1;
  --color-accent-content-violet: #794ed2;
  --color-accent-content-purple: #b130b3;
  --color-accent-content-pink: #bc2f86;
  --color-accent-foreground-red: #fff2f2;
  --color-accent-foreground-orange: #fff4ec;
  --color-accent-foreground-yellow: #fef5e2;
  --color-accent-foreground-lime: #f4f8e1;
  --color-accent-foreground-green: #e2fbe8;
  --color-accent-foreground-teal: #dffbf3;
  --color-accent-foreground-sky: #e2faff;
  --color-accent-foreground-blue: #eff5ff;
  --color-accent-foreground-indigo: #f3f5ff;
  --color-accent-foreground-violet: #f6f3ff;
  --color-accent-foreground-purple: #fbf3ff;
  --color-accent-foreground-pink: #fff2fc;
  --color-accent-border-red: #ffb9ba;
  --color-accent-border-orange: #fdbe8d;
  --color-accent-border-yellow: #f3c95b;
  --color-accent-border-lime: #bed954;
  --color-accent-border-green: #6ce5a2;
  --color-accent-border-teal: #62e1ca;
  --color-accent-border-sky: #78daeb;
  --color-accent-border-blue: #a8cfff;
  --color-accent-border-indigo: #bbcaff;
  --color-accent-border-violet: #d2c3ff;
  --color-accent-border-purple: #ebbaf9;
  --color-accent-border-pink: #ffb5ee;
  --color-on-functional-content-high: #ffffff;
  --color-on-functional-content-medium: rgba(255, 255, 255, 0.82);
  --color-on-functional-content-low: rgba(255, 255, 255, 0.53);
  --color-on-functional-border-default: rgba(255, 255, 255, 0.4);
  --color-on-accent-content-high: #ffffff;
  --color-on-accent-content-medium: rgba(255, 255, 255, 0.82);
  --color-on-accent-content-low: rgba(255, 255, 255, 0.53);
  --color-on-accent-border-default: rgba(255, 255, 255, 0.4);
  --color-brand-logo: #000000;
  --color-secondary-foreground-default: #3b82f6;
  --color-secondary-foreground-hover: #2570eb;
  --color-secondary-foreground-pressed: #1d64d8;
  --color-secondary-content-default: #3b82f6;
  --color-secondary-content-hover: #2570eb;
  --color-secondary-content-pressed: #1d64d8;
  --color-secondary-border-default: #bfd7fe;
  --color-palette-primary-100: #ffecdb;
  --color-palette-primary-200: #c9d0f4;
  --color-palette-primary-300: #a5b3eb;
  --color-palette-primary-400: #798be1;
  --color-palette-primary-500: #4d76ff;
  --color-palette-primary-600: #304ffe;
  --color-palette-primary-700: #13acc7;
  --color-palette-primary-800: #3a3697;
  --color-palette-primary-900: #313078;
  --color-palette-primary-950: #23214a;
  --color-palette-primary-025: #fbfbfe;
  --color-palette-primary-050: #f2f3fc;
  --color-palette-secondary-100: #dbe8fe;
  --color-palette-secondary-200: #bfd7fe;
  --color-palette-secondary-300: #93bbfd;
  --color-palette-secondary-400: #609afa;
  --color-palette-secondary-500: #3b82f6;
  --color-palette-secondary-600: #2570eb;
  --color-palette-secondary-700: #1d64d8;
  --color-palette-secondary-800: #1e55af;
  --color-palette-secondary-900: #1e478a;
  --color-palette-secondary-950: #172e54;
  --color-palette-secondary-025: #f5f9ff;
  --color-palette-secondary-050: #eff5ff;
  --color-palette-red-100: #ffd3d4;
  --color-palette-red-200: #ffb9ba;
  --color-palette-red-300: #fc8e8e;
  --color-palette-red-400: #ef5f5e;
  --color-palette-red-500: #c5312f;
  --color-palette-red-600: #a3201a;
  --color-palette-red-700: #881a10;
  --color-palette-red-800: #6f160d;
  --color-palette-red-900: #5e170f;
  --color-palette-red-950: #4d1513;
  --color-palette-red-025: #fff2f2;
  --color-palette-red-050: #ffe8e8;
  --color-palette-orange-100: #fed7b8;
  --color-palette-orange-200: #fdbe8d;
  --color-palette-orange-300: #f5984c;
  --color-palette-orange-400: #de7823;
  --color-palette-orange-500: #bd5904;
  --color-palette-orange-600: #a24604;
  --color-palette-orange-700: #863508;
  --color-palette-orange-800: #712b0e;
  --color-palette-orange-900: #5f2611;
  --color-palette-orange-950: #4e1e0b;
  --color-palette-orange-025: #fff4ec;
  --color-palette-orange-050: #fde9da;
  --color-palette-yellow-100: #fbde93;
  --color-palette-yellow-200: #f3c95b;
  --color-palette-yellow-300: #ddac29;
  --color-palette-yellow-400: #c38f04;
  --color-palette-yellow-500: #ab7506;
  --color-palette-yellow-600: #936111;
  --color-palette-yellow-700: #7d4f11;
  --color-palette-yellow-800: #663e0f;
  --color-palette-yellow-900: #52310d;
  --color-palette-yellow-950: #422706;
  --color-palette-yellow-025: #fef5e2;
  --color-palette-yellow-050: #ffedc1;
  --color-palette-lime-100: #d8ea88;
  --color-palette-lime-200: #bed954;
  --color-palette-lime-300: #a2c132;
  --color-palette-lime-400: #83a41f;
  --color-palette-lime-500: #6b8b1e;
  --color-palette-lime-600: #54751b;
  --color-palette-lime-700: #436119;
  --color-palette-lime-800: #354e13;
  --color-palette-lime-900: #2c3f0f;
  --color-palette-lime-950: #24320a;
  --color-palette-lime-025: #f4f8e1;
  --color-palette-lime-050: #eaf3bb;
  --color-palette-green-100: #9af2be;
  --color-palette-green-200: #6ce5a2;
  --color-palette-green-300: #52cc85;
  --color-palette-green-400: #46aa67;
  --color-palette-green-500: #3d8c4e;
  --color-palette-green-600: #35743f;
  --color-palette-green-700: #326032;
  --color-palette-green-800: #2b4f2a;
  --color-palette-green-900: #204024;
  --color-palette-green-950: #15341c;
  --color-palette-green-025: #e2fbe8;
  --color-palette-green-050: #cbf9d9;
  --color-palette-teal-100: #9cf0de;
  --color-palette-teal-200: #62e1ca;
  --color-palette-teal-300: #3fc6af;
  --color-palette-teal-400: #2aa694;
  --color-palette-teal-500: #1e8878;
  --color-palette-teal-600: #1d6e61;
  --color-palette-teal-700: #1c5950;
  --color-palette-teal-800: #1c4841;
  --color-palette-teal-900: #1a3c38;
  --color-palette-teal-950: #14322f;
  --color-palette-teal-025: #dffbf3;
  --color-palette-teal-050: #c5f9ec;
  --color-palette-sky-100: #a8e9f4;
  --color-palette-sky-200: #78daeb;
  --color-palette-sky-300: #4dbcd3;
  --color-palette-sky-400: #2d9bb3;
  --color-palette-sky-500: #0f7a94;
  --color-palette-sky-600: #14657f;
  --color-palette-sky-700: #1e566f;
  --color-palette-sky-800: #1f475d;
  --color-palette-sky-900: #193a4d;
  --color-palette-sky-950: #143041;
  --color-palette-sky-025: #e2faff;
  --color-palette-sky-050: #ccf5fc;
  --color-palette-blue-100: #c5dffe;
  --color-palette-blue-200: #a8cfff;
  --color-palette-blue-300: #85b0f4;
  --color-palette-blue-400: #5c8bdc;
  --color-palette-blue-500: #3268c4;
  --color-palette-blue-600: #2451b0;
  --color-palette-blue-700: #234195;
  --color-palette-blue-800: #253378;
  --color-palette-blue-900: #212b61;
  --color-palette-blue-950: #1e2852;
  --color-palette-blue-025: #eff5ff;
  --color-palette-blue-050: #e0eeff;
  --color-palette-indigo-100: #d3dcff;
  --color-palette-indigo-200: #bbcaff;
  --color-palette-indigo-300: #97aafe;
  --color-palette-indigo-400: #6c7fe9;
  --color-palette-indigo-500: #4a58d1;
  --color-palette-indigo-600: #4344bc;
  --color-palette-indigo-700: #3c36a2;
  --color-palette-indigo-800: #362d8a;
  --color-palette-indigo-900: #33276c;
  --color-palette-indigo-950: #312456;
  --color-palette-indigo-025: #f3f5ff;
  --color-palette-indigo-050: #e6ebfe;
  --color-palette-violet-100: #e2d8ff;
  --color-palette-violet-200: #d2c3ff;
  --color-palette-violet-300: #b7a0f8;
  --color-palette-violet-400: #9275e8;
  --color-palette-violet-500: #794ed2;
  --color-palette-violet-600: #6937be;
  --color-palette-violet-700: #6026a4;
  --color-palette-violet-800: #542087;
  --color-palette-violet-900: #46216a;
  --color-palette-violet-950: #392151;
  --color-palette-violet-025: #f6f3ff;
  --color-palette-violet-050: #f0eaff;
  --color-palette-purple-100: #f0d3fc;
  --color-palette-purple-200: #ebbaf9;
  --color-palette-purple-300: #dd94ea;
  --color-palette-purple-400: #c962d4;
  --color-palette-purple-500: #b130b3;
  --color-palette-purple-600: #960f97;
  --color-palette-purple-700: #810c80;
  --color-palette-purple-800: #6b0e69;
  --color-palette-purple-900: #581355;
  --color-palette-purple-950: #491945;
  --color-palette-purple-025: #fbf3ff;
  --color-palette-purple-050: #f8e8ff;
  --color-palette-pink-100: #ffd0f5;
  --color-palette-pink-200: #ffb5ee;
  --color-palette-pink-300: #f588d6;
  --color-palette-pink-400: #de56af;
  --color-palette-pink-500: #bc2f86;
  --color-palette-pink-600: #a01868;
  --color-palette-pink-700: #881354;
  --color-palette-pink-800: #701445;
  --color-palette-pink-900: #5b1538;
  --color-palette-pink-950: #4d192e;
  --color-palette-pink-025: #fff2fc;
  --color-palette-pink-050: #ffe6f9;
  --color-palette-neutral-variant-100: #a7b1c4;
  --color-palette-neutral-variant-200: #8b97ae;
  --color-palette-neutral-variant-300: #6c7994;
  --color-palette-neutral-variant-400: #4d5b76;
  --color-palette-neutral-variant-500: #3f4c66;
  --color-palette-neutral-variant-600: #323e56;
  --color-palette-neutral-variant-700: #263146;
  --color-palette-neutral-variant-800: #1b2437;
  --color-palette-neutral-variant-900: #131a28;
  --color-palette-neutral-variant-999: #0b1019;
  --color-palette-neutral-variant-000: #ffffff;
  --color-palette-neutral-variant-010: #f9fafb;
  --color-palette-neutral-variant-020: #f0f2f6;
  --color-palette-neutral-variant-030: #e4e7ee;
  --color-palette-neutral-variant-040: #d4d9e4;
  --color-palette-neutral-variant-050: #c0c7d6;
  --color-palette-neutral-100: #bebebe;
  --color-palette-neutral-200: #a5a5a5;
  --color-palette-neutral-300: #888888;
  --color-palette-neutral-400: #676767;
  --color-palette-neutral-500: #555555;
  --color-palette-neutral-600: #434343;
  --color-palette-neutral-700: #1e1e1e;
  --color-palette-neutral-800: #141414;
  --color-palette-neutral-900: #101010;
  --color-palette-neutral-999: #000000;
  --color-palette-neutral-000: #ffffff;
  --color-palette-neutral-010: #fbfbfb;
  --color-palette-neutral-020: #f5f5f5;
  --color-palette-neutral-030: #ececec;
  --color-palette-neutral-040: #e0e0e0;
  --color-palette-neutral-050: #d1d1d1;
  --typography-disp-display-1-strong-font-family: Prompt;
  --typography-disp-display-1-strong-font-weight: Bold;
  --typography-disp-display-1-strong-line-height: 82;
  --typography-disp-display-1-strong-font-size: 58;
  --typography-disp-display-1-strong-letter-spacing: 0%;
  --typography-disp-display-1-strong-paragraph-spacing: 0;
  --typography-disp-display-1-strong-text-case: none;
  --typography-disp-display-1-strong-text-decoration: none;
  --typography-disp-display-1-normal-font-family: Prompt;
  --typography-disp-display-1-normal-font-weight: Medium;
  --typography-disp-display-1-normal-line-height: 82;
  --typography-disp-display-1-normal-font-size: 58;
  --typography-disp-display-1-normal-letter-spacing: 0%;
  --typography-disp-display-1-normal-paragraph-spacing: 0;
  --typography-disp-display-1-normal-text-case: none;
  --typography-disp-display-1-normal-text-decoration: none;
  --typography-disp-display-2-strong-font-family: Prompt;
  --typography-disp-display-2-strong-font-weight: Bold;
  --typography-disp-display-2-strong-line-height: 72;
  --typography-disp-display-2-strong-font-size: 52;
  --typography-disp-display-2-strong-letter-spacing: 0%;
  --typography-disp-display-2-strong-paragraph-spacing: 0;
  --typography-disp-display-2-strong-text-case: none;
  --typography-disp-display-2-strong-text-decoration: none;
  --typography-disp-display-2-normal-font-family: Prompt;
  --typography-disp-display-2-normal-font-weight: Medium;
  --typography-disp-display-2-normal-line-height: 72;
  --typography-disp-display-2-normal-font-size: 52;
  --typography-disp-display-2-normal-letter-spacing: 0%;
  --typography-disp-display-2-normal-paragraph-spacing: 0;
  --typography-disp-display-2-normal-text-case: none;
  --typography-disp-display-2-normal-text-decoration: none;
  --typography-disp-display-3-strong-font-family: Prompt;
  --typography-disp-display-3-strong-font-weight: Bold;
  --typography-disp-display-3-strong-line-height: 64;
  --typography-disp-display-3-strong-font-size: 46;
  --typography-disp-display-3-strong-letter-spacing: 0%;
  --typography-disp-display-3-strong-paragraph-spacing: 0;
  --typography-disp-display-3-strong-text-case: none;
  --typography-disp-display-3-strong-text-decoration: none;
  --typography-disp-display-3-normal-font-family: Prompt;
  --typography-disp-display-3-normal-font-weight: Medium;
  --typography-disp-display-3-normal-line-height: 64;
  --typography-disp-display-3-normal-font-size: 46;
  --typography-disp-display-3-normal-letter-spacing: 0%;
  --typography-disp-display-3-normal-paragraph-spacing: 0;
  --typography-disp-display-3-normal-text-case: none;
  --typography-disp-display-3-normal-text-decoration: none;
  --typography-disp-display-4-strong-font-family: Prompt;
  --typography-disp-display-4-strong-font-weight: Bold;
  --typography-disp-display-4-strong-line-height: 58;
  --typography-disp-display-4-strong-font-size: 41;
  --typography-disp-display-4-strong-letter-spacing: 0%;
  --typography-disp-display-4-strong-paragraph-spacing: 0;
  --typography-disp-display-4-strong-text-case: none;
  --typography-disp-display-4-strong-text-decoration: none;
  --typography-disp-display-4-normal-font-family: Prompt;
  --typography-disp-display-4-normal-font-weight: Medium;
  --typography-disp-display-4-normal-line-height: 58;
  --typography-disp-display-4-normal-font-size: 41;
  --typography-disp-display-4-normal-letter-spacing: 0%;
  --typography-disp-display-4-normal-paragraph-spacing: 0;
  --typography-disp-display-4-normal-text-case: none;
  --typography-disp-display-4-normal-text-decoration: none;
  --typography-head-headline-1-strong-font-family: Prompt;
  --typography-head-headline-1-strong-font-weight: Bold;
  --typography-head-headline-1-strong-line-height: 52;
  --typography-head-headline-1-strong-font-size: 32;
  --typography-head-headline-1-strong-letter-spacing: 0%;
  --typography-head-headline-1-strong-paragraph-spacing: 0;
  --typography-head-headline-1-strong-text-case: none;
  --typography-head-headline-1-strong-text-decoration: none;
  --typography-head-headline-1-normal-font-family: Prompt;
  --typography-head-headline-1-normal-font-weight: Medium;
  --typography-head-headline-1-normal-line-height: 52;
  --typography-head-headline-1-normal-font-size: 32;
  --typography-head-headline-1-normal-letter-spacing: 0%;
  --typography-head-headline-1-normal-paragraph-spacing: 0;
  --typography-head-headline-1-normal-text-case: none;
  --typography-head-headline-1-normal-text-decoration: none;
  --typography-head-headline-2-strong-font-family: Prompt;
  --typography-head-headline-2-strong-font-weight: Bold;
  --typography-head-headline-2-strong-line-height: 46;
  --typography-head-headline-2-strong-font-size: 29;
  --typography-head-headline-2-strong-letter-spacing: 0%;
  --typography-head-headline-2-strong-paragraph-spacing: 0;
  --typography-head-headline-2-strong-text-case: none;
  --typography-head-headline-2-strong-text-decoration: none;
  --typography-head-headline-2-normal-font-family: Prompt;
  --typography-head-headline-2-normal-font-weight: Medium;
  --typography-head-headline-2-normal-line-height: 46;
  --typography-head-headline-2-normal-font-size: 29;
  --typography-head-headline-2-normal-letter-spacing: 0%;
  --typography-head-headline-2-normal-paragraph-spacing: 0;
  --typography-head-headline-2-normal-text-case: none;
  --typography-head-headline-2-normal-text-decoration: none;
  --typography-head-headline-3-strong-font-family: Prompt;
  --typography-head-headline-3-strong-font-weight: Bold;
  --typography-head-headline-3-strong-line-height: 42;
  --typography-head-headline-3-strong-font-size: 26;
  --typography-head-headline-3-strong-letter-spacing: 0%;
  --typography-head-headline-3-strong-paragraph-spacing: 0;
  --typography-head-headline-3-strong-text-case: none;
  --typography-head-headline-3-strong-text-decoration: none;
  --typography-head-headline-3-normal-font-family: Prompt;
  --typography-head-headline-3-normal-font-weight: Medium;
  --typography-head-headline-3-normal-line-height: 42;
  --typography-head-headline-3-normal-font-size: 26;
  --typography-head-headline-3-normal-letter-spacing: 0%;
  --typography-head-headline-3-normal-paragraph-spacing: 0;
  --typography-head-headline-3-normal-text-case: none;
  --typography-head-headline-3-normal-text-decoration: none;
  --typography-head-headline-4-strong-font-family: Prompt;
  --typography-head-headline-4-strong-font-weight: Bold;
  --typography-head-headline-4-strong-line-height: 36;
  --typography-head-headline-4-strong-font-size: 23;
  --typography-head-headline-4-strong-letter-spacing: 0%;
  --typography-head-headline-4-strong-paragraph-spacing: 0;
  --typography-head-headline-4-strong-text-case: none;
  --typography-head-headline-4-strong-text-decoration: none;
  --typography-head-headline-4-normal-font-family: Prompt;
  --typography-head-headline-4-normal-font-weight: Medium;
  --typography-head-headline-4-normal-line-height: 36;
  --typography-head-headline-4-normal-font-size: 23;
  --typography-head-headline-4-normal-letter-spacing: 0%;
  --typography-head-headline-4-normal-paragraph-spacing: 0;
  --typography-head-headline-4-normal-text-case: none;
  --typography-head-headline-4-normal-text-decoration: none;
  --typography-head-headline-5-strong-font-family: Prompt;
  --typography-head-headline-5-strong-font-weight: Bold;
  --typography-head-headline-5-strong-line-height: 32;
  --typography-head-headline-5-strong-font-size: 20;
  --typography-head-headline-5-strong-letter-spacing: 0%;
  --typography-head-headline-5-strong-paragraph-spacing: 0;
  --typography-head-headline-5-strong-text-case: none;
  --typography-head-headline-5-strong-text-decoration: none;
  --typography-head-headline-5-normal-font-family: Prompt;
  --typography-head-headline-5-normal-font-weight: Medium;
  --typography-head-headline-5-normal-line-height: 32;
  --typography-head-headline-5-normal-font-size: 20;
  --typography-head-headline-5-normal-letter-spacing: 0%;
  --typography-head-headline-5-normal-paragraph-spacing: 0;
  --typography-head-headline-5-normal-text-case: none;
  --typography-head-headline-5-normal-text-decoration: none;
  --typography-head-title-1-strong-font-family: Prompt;
  --typography-head-title-1-strong-font-weight: Bold;
  --typography-head-title-1-strong-line-height: 28;
  --typography-head-title-1-strong-font-size: 18;
  --typography-head-title-1-strong-letter-spacing: 0%;
  --typography-head-title-1-strong-paragraph-spacing: 0;
  --typography-head-title-1-strong-text-case: none;
  --typography-head-title-1-strong-text-decoration: none;
  --typography-head-title-1-normal-font-family: Prompt;
  --typography-head-title-1-normal-font-weight: Medium;
  --typography-head-title-1-normal-line-height: 28;
  --typography-head-title-1-normal-font-size: 18;
  --typography-head-title-1-normal-letter-spacing: 0%;
  --typography-head-title-1-normal-paragraph-spacing: 0;
  --typography-head-title-1-normal-text-case: none;
  --typography-head-title-1-normal-text-decoration: none;
  --typography-head-title-2-strong-font-family: Prompt;
  --typography-head-title-2-strong-font-weight: Bold;
  --typography-head-title-2-strong-line-height: 26;
  --typography-head-title-2-strong-font-size: 16;
  --typography-head-title-2-strong-letter-spacing: 0%;
  --typography-head-title-2-strong-paragraph-spacing: 0;
  --typography-head-title-2-strong-text-case: none;
  --typography-head-title-2-strong-text-decoration: none;
  --typography-head-title-2-normal-font-family: Prompt;
  --typography-head-title-2-normal-font-weight: Medium;
  --typography-head-title-2-normal-line-height: 26;
  --typography-head-title-2-normal-font-size: 16;
  --typography-head-title-2-normal-letter-spacing: 0%;
  --typography-head-title-2-normal-paragraph-spacing: 0;
  --typography-head-title-2-normal-text-case: none;
  --typography-head-title-2-normal-text-decoration: none;
  --typography-head-overline-strong-font-family: Prompt;
  --typography-head-overline-strong-font-weight: Bold;
  --typography-head-overline-strong-line-height: 20;
  --typography-head-overline-strong-font-size: 12;
  --typography-head-overline-strong-letter-spacing: 0%;
  --typography-head-overline-strong-paragraph-spacing: 0;
  --typography-head-overline-strong-text-case: Title case;
  --typography-head-overline-strong-text-decoration: none;
  --typography-head-overline-normal-font-family: Prompt;
  --typography-head-overline-normal-font-weight: Medium;
  --typography-head-overline-normal-line-height: 20;
  --typography-head-overline-normal-font-size: 12;
  --typography-head-overline-normal-letter-spacing: 0%;
  --typography-head-overline-normal-paragraph-spacing: 0;
  --typography-head-overline-normal-text-case: Title case;
  --typography-head-overline-normal-text-decoration: none;
  --typography-text-body-1-strong-font-family: Prompt;
  --typography-text-body-1-strong-font-weight: Medium;
  --typography-text-body-1-strong-line-height: 24;
  --typography-text-body-1-strong-font-size: 16;
  --typography-text-body-1-strong-letter-spacing: 0%;
  --typography-text-body-1-strong-paragraph-spacing: 0;
  --typography-text-body-1-strong-text-case: none;
  --typography-text-body-1-strong-text-decoration: none;
  --typography-text-body-1-normal-font-family: Prompt;
  --typography-text-body-1-normal-font-weight: Regular;
  --typography-text-body-1-normal-line-height: 24;
  --typography-text-body-1-normal-font-size: 16;
  --typography-text-body-1-normal-letter-spacing: 0%;
  --typography-text-body-1-normal-paragraph-spacing: 0;
  --typography-text-body-1-normal-text-case: none;
  --typography-text-body-1-normal-text-decoration: none;
  --typography-text-body-2-strong-font-family: Prompt;
  --typography-text-body-2-strong-font-weight: Medium;
  --typography-text-body-2-strong-line-height: 22;
  --typography-text-body-2-strong-font-size: 14;
  --typography-text-body-2-strong-letter-spacing: 0%;
  --typography-text-body-2-strong-paragraph-spacing: 0;
  --typography-text-body-2-strong-text-case: none;
  --typography-text-body-2-strong-text-decoration: none;
  --typography-text-body-2-normal-font-family: Prompt;
  --typography-text-body-2-normal-font-weight: Regular;
  --typography-text-body-2-normal-line-height: 22;
  --typography-text-body-2-normal-font-size: 14;
  --typography-text-body-2-normal-letter-spacing: 0%;
  --typography-text-body-2-normal-paragraph-spacing: 0;
  --typography-text-body-2-normal-text-case: none;
  --typography-text-body-2-normal-text-decoration: none;
  --typography-text-caption-strong-font-family: Prompt;
  --typography-text-caption-strong-font-weight: Medium;
  --typography-text-caption-strong-line-height: 18;
  --typography-text-caption-strong-font-size: 12;
  --typography-text-caption-strong-letter-spacing: 0%;
  --typography-text-caption-strong-paragraph-spacing: 0;
  --typography-text-caption-strong-text-case: none;
  --typography-text-caption-strong-text-decoration: none;
  --typography-text-caption-normal-font-family: Prompt;
  --typography-text-caption-normal-font-weight: Regular;
  --typography-text-caption-normal-line-height: 18;
  --typography-text-caption-normal-font-size: 12;
  --typography-text-caption-normal-letter-spacing: 0%;
  --typography-text-caption-normal-paragraph-spacing: 0;
  --typography-text-caption-normal-text-case: none;
  --typography-text-caption-normal-text-decoration: none;
  --typography-text-tiny-strong-font-family: Prompt;
  --typography-text-tiny-strong-font-weight: Medium;
  --typography-text-tiny-strong-line-height: 14;
  --typography-text-tiny-strong-font-size: 9;
  --typography-text-tiny-strong-letter-spacing: 0%;
  --typography-text-tiny-strong-paragraph-spacing: 0;
  --typography-text-tiny-strong-text-case: none;
  --typography-text-tiny-strong-text-decoration: none;
  --typography-text-tiny-normal-font-family: Prompt;
  --typography-text-tiny-normal-font-weight: Regular;
  --typography-text-tiny-normal-line-height: 14;
  --typography-text-tiny-normal-font-size: 9;
  --typography-text-tiny-normal-letter-spacing: 0%;
  --typography-text-tiny-normal-paragraph-spacing: 0;
  --typography-text-tiny-normal-text-case: none;
  --typography-text-tiny-normal-text-decoration: none;
  --typography-extra-button-large-font-family: Prompt;
  --typography-extra-button-large-font-weight: Medium;
  --typography-extra-button-large-line-height: 24;
  --typography-extra-button-large-font-size: 16;
  --typography-extra-button-large-letter-spacing: 2%;
  --typography-extra-button-large-paragraph-spacing: 0;
  --typography-extra-button-large-text-case: Title case;
  --typography-extra-button-large-text-decoration: none;
  --typography-extra-button-medium-font-family: Prompt;
  --typography-extra-button-medium-font-weight: Medium;
  --typography-extra-button-medium-line-height: 22;
  --typography-extra-button-medium-font-size: 14;
  --typography-extra-button-medium-letter-spacing: 2%;
  --typography-extra-button-medium-paragraph-spacing: 0;
  --typography-extra-button-medium-text-case: Title case;
  --typography-extra-button-medium-text-decoration: none;
  --typography-extra-button-small-font-family: Prompt;
  --typography-extra-button-small-font-weight: Medium;
  --typography-extra-button-small-line-height: 18;
  --typography-extra-button-small-font-size: 12;
  --typography-extra-button-small-letter-spacing: 2%;
  --typography-extra-button-small-paragraph-spacing: 0;
  --typography-extra-button-small-text-case: Title case;
  --typography-extra-button-small-text-decoration: none;
  --semantic-font-size-display1: 58;
  --semantic-font-size-display2: 52;
  --semantic-font-size-display3: 46;
  --semantic-font-size-display4: 41;
  --semantic-font-size-head1: 32;
  --semantic-font-size-head2: 29;
  --semantic-font-size-head3: 26;
  --semantic-font-size-head4: 23;
  --semantic-font-size-head5: 20;
  --semantic-font-size-title1: 18;
  --semantic-font-size-title2: 16;
  --semantic-font-size-body1: 16;
  --semantic-font-size-body2: 14;
  --semantic-font-size-caption: 12;
  --semantic-font-size-overline: 12;
  --semantic-font-size-tiny: 9;
  --semantic-font-size-cta-l: 16;
  --semantic-font-size-cta-m: 14;
  --semantic-font-size-cta-s: 12;
  --semantic-line-height-head-multiple: 1.6;
  --semantic-line-height-disp-multiple: 1.4;
  --semantic-line-height-text-multiple: 1.5;
  --semantic-line-height-cta-multiple: 1.5;
  --semantic-line-height-display1: 82;
  --semantic-line-height-display2: 72;
  --semantic-line-height-display3: 64;
  --semantic-line-height-display4: 58;
  --semantic-line-height-head1: 52;
  --semantic-line-height-head2: 46;
  --semantic-line-height-head3: 42;
  --semantic-line-height-head4: 36;
  --semantic-line-height-head5: 32;
  --semantic-line-height-title1: 28;
  --semantic-line-height-title2: 26;
  --semantic-line-height-body1: 24;
  --semantic-line-height-body2: 22;
  --semantic-line-height-caption: 18;
  --semantic-line-height-overline: 20;
  --semantic-line-height-tiny: 14;
  --semantic-line-height-cta-l: 24;
  --semantic-line-height-cta-m: 22;
  --semantic-line-height-cta-s: 18;
  --semantic-text-case-disp: none;
  --semantic-text-case-head: none;
  --semantic-text-case-text: none;
  --semantic-text-case-cta: Title case;
  --semantic-font-family-disp: Prompt;
  --semantic-font-family-head: Prompt;
  --semantic-font-family-text: Prompt;
  --semantic-font-family-cta: Prompt;
  --semantic-font-weight-text-normal: Regular;
  --semantic-font-weight-text-strong: Medium;
  --semantic-font-weight-head-normal: Medium;
  --semantic-font-weight-head-strong: Bold;
  --semantic-font-weight-disp-normal: Medium;
  --semantic-font-weight-disp-strong: Bold;
  --semantic-font-weight-cta-normal: Medium;
  --semantic-paragraph-spacing-disp: 0;
  --semantic-paragraph-spacing-head: 0;
  --semantic-paragraph-spacing-text: 0;
  --semantic-paragraph-spacing-cta: 0;
  --semantic-letter-spacing-disp: 0%;
  --semantic-letter-spacing-head: 0%;
  --semantic-letter-spacing-text: 0%;
  --semantic-letter-spacing-cta: 2%;
  --semantic-text-decor-disp: none;
  --semantic-text-decor-head: none;
  --semantic-text-decor-text: none;
  --semantic-text-decor-cta: none;
  --border-radius-max: 240;
  --border-radius-xl: 16;
  --border-radius-lg: 12;
  --border-radius-md: 8;
  --border-radius-sm: 6;
  --border-radius-xs: 4;
  --border-radius-toggle: 1;
  --border-radius-stepup: 4;
  --border-radius-stepdown: 2;
  --border-radius-base: 8;
  --padding-xxl: 20;
  --padding-xl: 16;
  --padding-lg: 12;
  --padding-md: 8;
  --padding-sm: 6;
  --padding-xs: 4;
  --padding-xxs: 2;
  --padding-none: 0;
  --size-comp-xxs: 20rem;
  --size-comp-xs: 24rem;
  --size-comp-sm: 32rem;
  --size-comp-md: 40rem;
  --size-comp-lg: 48rem;
  --size-comp-xl: 56rem;
  --size-comp-base: 24rem;
  --size-comp-stepper: 8rem;
  --border-width-regular: 1;
  --border-width-bold: 2;
  --border-width-base: 1;
  --shadow-below-xl-0-color: #00000029;
  --shadow-below-xl-0-type: dropShadow;
  --shadow-below-xl-0-x: 0;
  --shadow-below-xl-0-y: 16;
  --shadow-below-xl-0-blur: 32;
  --shadow-below-xl-0-spread: -4;
  --shadow-below-xl-1-color: #00000029;
  --shadow-below-xl-1-type: dropShadow;
  --shadow-below-xl-1-x: 0;
  --shadow-below-xl-1-y: 0;
  --shadow-below-xl-1-blur: 1;
  --shadow-below-xl-1-spread: 0;
  --shadow-below-md-0-color: #0000001f;
  --shadow-below-md-0-type: dropShadow;
  --shadow-below-md-0-x: 0;
  --shadow-below-md-0-y: 8;
  --shadow-below-md-0-blur: 24;
  --shadow-below-md-0-spread: -4;
  --shadow-below-md-1-color: #0000001f;
  --shadow-below-md-1-type: dropShadow;
  --shadow-below-md-1-x: 0;
  --shadow-below-md-1-y: 0;
  --shadow-below-md-1-blur: 1;
  --shadow-below-md-1-spread: 0;
  --shadow-below-sm-0-color: #00000014;
  --shadow-below-sm-0-type: dropShadow;
  --shadow-below-sm-0-x: 0;
  --shadow-below-sm-0-y: 4;
  --shadow-below-sm-0-blur: 16;
  --shadow-below-sm-0-spread: -4;
  --shadow-below-sm-1-color: #0000001f;
  --shadow-below-sm-1-type: dropShadow;
  --shadow-below-sm-1-x: 0;
  --shadow-below-sm-1-y: 0;
  --shadow-below-sm-1-blur: 1;
  --shadow-below-sm-1-spread: 0;
  --shadow-below-xs-0-color: #0000000f;
  --shadow-below-xs-0-type: dropShadow;
  --shadow-below-xs-0-x: 0;
  --shadow-below-xs-0-y: 2;
  --shadow-below-xs-0-blur: 4;
  --shadow-below-xs-0-spread: -4;
  --shadow-below-xs-1-color: #0000001f;
  --shadow-below-xs-1-type: dropShadow;
  --shadow-below-xs-1-x: 0;
  --shadow-below-xs-1-y: 0;
  --shadow-below-xs-1-blur: 1;
  --shadow-below-xs-1-spread: 0;
  --state-focused-default-0-x: 0;
  --state-focused-default-0-y: 0;
  --state-focused-default-0-blur: 0;
  --state-focused-default-0-spread: 2;
  --state-focused-default-0-color: #ffffffbf;
  --state-focused-default-0-type: dropShadow;
  --state-focused-default-1-x: 0;
  --state-focused-default-1-y: 0;
  --state-focused-default-1-blur: 0;
  --state-focused-default-1-spread: 4;
  --state-focused-default-1-color: #0b1019;
  --state-focused-default-1-type: dropShadow;
  --state-focused-primary-0-x: 0;
  --state-focused-primary-0-y: 0;
  --state-focused-primary-0-blur: 0;
  --state-focused-primary-0-spread: 2;
  --state-focused-primary-0-color: #ffffffbf;
  --state-focused-primary-0-type: dropShadow;
  --state-focused-primary-1-x: 0;
  --state-focused-primary-1-y: 0;
  --state-focused-primary-1-blur: 0;
  --state-focused-primary-1-spread: 4;
  --state-focused-primary-1-color: #c9d0f4;
  --state-focused-primary-1-type: dropShadow;
  --state-focused-error-0-x: 0;
  --state-focused-error-0-y: 0;
  --state-focused-error-0-blur: 0;
  --state-focused-error-0-spread: 2;
  --state-focused-error-0-color: #ffffffbf;
  --state-focused-error-0-type: dropShadow;
  --state-focused-error-1-x: 0;
  --state-focused-error-1-y: 0;
  --state-focused-error-1-blur: 0;
  --state-focused-error-1-spread: 4;
  --state-focused-error-1-color: #ffb9ba;
  --state-focused-error-1-type: dropShadow;
  --font-size-base: 16;
  --font-size-typescale: 1.125;
  --font-size-scale05: 8.878;
  --font-size-scale06: 9.988;
  --font-size-scale07: 11.237;
  --font-size-scale08: 12.642;
  --font-size-scale09: 14.222;
  --font-size-scale10: 16;
  --font-size-scale11: 18;
  --font-size-scale12: 20.25;
  --font-size-scale13: 22.781;
  --font-size-scale14: 25.629;
  --font-size-scale15: 28.833;
  --font-size-scale16: 32.437;
  --font-size-scale17: 36.492;
  --font-size-scale18: 41.053;
  --font-size-scale19: 46.185;
  --font-size-scale20: 51.958;
  --font-size-scale21: 58.453;
  --font-size-scale22: 65.76;
  --font-families-base-head: Prompt;
  --font-families-base: Prompt;
  --font-weight-base-normal: Regular;
  --font-weight-base-strong: Medium;
  --font-weight-base-head-normal: Medium;
  --font-weight-base-head-strong: Bold;
  --opacity-black-100: 0.25;
  --opacity-black-200: 0.35;
  --opacity-black-300: 0.47;
  --opacity-black-400: 0.6;
  --opacity-black-500: 0.67;
  --opacity-black-600: 0.74;
  --opacity-black-700: 0.8;
  --opacity-black-800: 0.87;
  --opacity-black-900: 0.94;
  --opacity-black-999: 1;
  --opacity-black-000: 0;
  --opacity-black-010: 0.02;
  --opacity-black-020: 0.04;
  --opacity-black-030: 0.08;
  --opacity-black-040: 0.12;
  --opacity-black-050: 0.18;
  --opacity-white-100: 0.75;
  --opacity-white-200: 0.65;
  --opacity-white-300: 0.53;
  --opacity-white-400: 0.4;
  --opacity-white-500: 0.33;
  --opacity-white-600: 0.26;
  --opacity-white-700: 0.2;
  --opacity-white-800: 0.13;
  --opacity-white-900: 0.6;
  --opacity-white-999: 0;
  --opacity-white-000: 1;
  --opacity-white-010: 0.98;
  --opacity-white-020: 0.96;
  --opacity-white-030: 0.92;
  --opacity-white-040: 0.88;
  --opacity-white-050: 0.82;
  --spacing-0: 0;
  --spacing-5: 2;
  --spacing-10: 4;
  --spacing-15: 6;
  --spacing-20: 8;
  --spacing-25: 10;
  --spacing-30: 12;
  --spacing-35: 14;
  --spacing-40: 16;
  --spacing-50: 20;
  --spacing-60: 24;
  --spacing-70: 28;
  --spacing-80: 32;
  --spacing-90: 36;
  --spacing-100: 40;
  --spacing-110: 44;
  --spacing-120: 48;
  --spacing-140: 56;
  --spacing-160: 64;
  --spacing-180: 72;
  --spacing-200: 80;
  --spacing-220: 88;
  --spacing-240: 96;
  --spacing-260: 104;
  --spacing-280: 112;
  --spacing-320: 128;
  --spacing-360: 144;
  --spacing-400: 160;
  --spacing-440: 176;
  --spacing-480: 192;
  --spacing-520: 208;
  --spacing-560: 224;
  --spacing-600: 240;
  --spacing-640: 256;
  --spacing-720: 288;
  --spacing-800: 320;
  --spacing-base: 16;
  --spacing-1p: 1;
}
