.form-check-input:checked {
  background-color: var(--comp-btn-color-background-primary-normal) !important;
  border-color: var(--comp-btn-color-background-primary-normal) !important;
}
.text-primary {
  color: var(--comp-btn-color-background-primary-normal) !important;
}
/* swal2 custom */
.swal2-styled.swal2-confirm {
  border-radius: 0.375rem !important;
  /* background-color: var(--comp-badge-color-background-primary) !important; */
  box-shadow: inset 0 -3px 0 0 rgb(34 41 47 / 20%) !important;
}
.swal2-styled.swal2-cancel {
  border-radius: 0.375rem !important;

  background-color: #6e7881 !important;
  box-shadow: inset 0 -3px 0 0 rgb(34 41 47 / 20%) !important;
}
.swal2-icon.swal2-warning {
  border-color: #ff9941 !important;
  color: #f8d786 !important;
}
.swal2-icon.swal2-error {
  border-color: #ff4545 !important;
  color: #ffbba1 !important;
}
.swal2-icon.swal2-question {
  border-color: #85dbff !important;
  color: #abe6ff !important;
}
.swal2-title {
  font-weight: normal !important;
}
.swal2-html-container {
  font-weight: 300 !important;
}
.swal2-popup {
  background: var(--color-base-foreground-elevated-0) !important;
  border-radius: 16px !important;
}
